import TimezoneSelect from 'react-timezone-select';
import classes from "./uploadSong.module.css";
import React, { useState } from 'react';


const TimezonePicker = ({selectTimezone,values}) => {
  //state for timezone
  const [selectedTimezone, setSelectedTimezone] = useState("Africa/Lagos");
  const [releaseTime, setReleaseTime] = useState("12:00");

  function time(time){
    selectTimezone((prev)=>({
      ...prev,
      ["selectedTime"]: time,
    })
  )}
  return (
    <div className='my-5'>
        <p className={classes.question}>Select Timezone
        </p>
      <TimezoneSelect
        value={selectedTimezone}
        onChange={(value)=>{
          selectTimezone((prev) => ({
              ...prev,
              ["selectedTimezone"]: value.label,
            }))
            setSelectedTimezone(value)
        }}
        labelStyle="altName"
      />
          <div className='my-5'>
        <p className={classes.question}>Select Time:
        </p>
        <input
          type="time"
          className="mt-2 p-2 border rounded"
          value={releaseTime}
          onChange={(e) => {
            setReleaseTime(e.target.value)
            time(e.target.value)
            console.log(releaseTime)
          }}
        />
    </div>
    </div>
  );
};

export default TimezonePicker;
